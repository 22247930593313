import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { AuthenticationFacade } from 'src/app/modules/authentication/state/authentication.facade';
import { Observable, throwError } from 'rxjs';
import { take, map, switchMap, catchError, retry } from 'rxjs/operators';
import { AuthenticationState } from 'src/app/modules/authentication/state/authentication.reducer';
import { AuthenticationService } from 'src/app/modules/authentication/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private authenticationFacade: AuthenticationFacade,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const routePath = this.activatedRoute.snapshot['_routerState'].url;
    return this.authenticationFacade.authenticationState$.pipe(
      take(1),
      map((e: AuthenticationState) => {
        let headers = request.headers;
        if (!!e && !!e.access_token) {
          // request = request.clone({
          //   // headers: request.headers.set('Authorization', `Bearer ${e.access_token}`),
          //   setHeaders: {
          //     Authorization: `Bearer ${e.access_token}`
          // }
          // });
          headers = headers.set('Authorization', `Bearer ${e.access_token}`);

        }
        if (request.url.includes('details/personal') || request.url.includes('details/employment')) {
          headers = headers.delete('content-type');
          // headers = headers.set('Content-Type', 'text/plain');
        } else {
          headers = headers.set('Content-Type', 'application/json');
        }
        this.toggleButtonDisable(true);
        const authReq = request.clone({ headers });
        return authReq;
      }),
      switchMap((newRequest: any) => {
        return next.handle(newRequest).pipe(
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.toggleButtonDisable(false);
            }
            return event;
          }),
          catchError((error: HttpErrorResponse) => {
            this.toggleButtonDisable(false);
            const errorStatus = error.status;
            if (errorStatus === 401) {
              this.toastr.warning(error.error.message || error.message);
              this.authService.logout('/');
            } else if (errorStatus === 500) {
              this.toastr.error(
                'Something went wrong! This is likely from us, we will fix it soon. Please try again later'
              );
            } else if (errorStatus === 0) {
              this.toastr.error('Unknown Error! Please check your internet and try again later');
          } else {
              this.toastr.error(error.error.message || error.message);
            }
            return throwError(error);
          })
        );
      })
    );
  }

  private toggleButtonDisable(value) {
    const buttons: any = document.getElementsByTagName('button');

    for (const button of buttons) {
      button.disabled = value;
    }
  }
}
