import { createAction, props } from '@ngrx/store';
import {
  LoanApplicationResultI,
  PersonalInformationData,
  EmploymentInformationData,
  OfferData,
  AccountDetailsData,
} from './loan-application.model';

export const sendLoanApplication = createAction(
  '[Loan Application] Send Loan Application',
  props<{
    loanApplication: LoanApplicationResultI;
  }>()
);

export const sendPersonalInformation = createAction(
  '[Loan Application] Send Personal Information',
  props<{
    personalInformation: PersonalInformationData;
  }>()
);

export const sendEmploymentInformation = createAction(
  '[Loan Application] Send Employment Information',
  props<{
    employmentInformation: EmploymentInformationData;
  }>()
);

export const sendOffer = createAction(
  '[Loan Application] Send Offer',
  props<{
    offer: OfferData;
  }>()
);

export const sendAccountDetails = createAction(
  '[Loan Application] Send Account Details',
  props<{
    accountDetails: AccountDetailsData;
  }>()
);

export const reset = createAction('[Loan Application] Reset');
