import { Injectable } from '@angular/core';
import {
  LoginData,
  AuthenticationResultI,
  CreateAccountData,
  CreatePaymentAccountData,
} from './state/authentication.models';
import { of } from 'rxjs';
import { ANONYMOUS_USER } from './state/authentication.reducer';
import { HttpRequesterService } from 'src/app/services/http/http-requester.service';
import { ApiRoutes } from 'src/app/enums/api-routes.enum';
import { Store } from '@ngrx/store';
import { sendAuthenticationSuccess } from './state/authentication.actions';
import { Router } from '@angular/router';
import { AuthenticationFacade } from './state/authentication.facade';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  auth: any;
  constructor(
    private authenticationFacade: AuthenticationFacade,
    private httpRequester: HttpRequesterService,
    private store: Store<{ AUTHENTICATION_FEATURE_KEY }>,
    private router: Router
  ) {
    this.authenticationFacade.authenticationState$.subscribe((auth: any) => {
      this.auth = auth;
    });
  }

  login({ email, pin }: LoginData) {
    return this.httpRequester.post(ApiRoutes.login, { email, pin });
  }

  createAccount(body: CreateAccountData) {
    return this.httpRequester.post(ApiRoutes.createAccount, body);
  }

  createPaymentAccount(body: CreatePaymentAccountData) {
    return this.httpRequester.post(ApiRoutes.createPaymentAccount, body);
  }

  logout(redirect?: string) {
    return of(ANONYMOUS_USER).subscribe((data: AuthenticationResultI) => {
      this.store.dispatch(sendAuthenticationSuccess({ authentication: data }));
      if (redirect) {
        this.router.navigate([redirect]);
      }
    });
  }

  saveToken(token) {
    return window.sessionStorage.setItem('mm-token', token)
  }

  getToken() {
    return window.sessionStorage.getItem('mm-token')
  }
}
