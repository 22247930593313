import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export const httpOptions = {
  headers: new HttpHeaders({
    // 'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class HttpRequesterService {
  apiBaseUrl: string;

  constructor(private http: HttpClient) {
    this.apiBaseUrl = environment.apiBaseUrl;
  }

  get(url: string) {
    const requestUrl = `${this.apiBaseUrl}${url}`;
    return this.http.get(requestUrl, httpOptions);
  }

  post(url: string, data: any) {
    const requestUrl = `${this.apiBaseUrl}${url}`;
    // const body = JSON.stringify(data);
    return this.http.post(requestUrl, data);
  }

  postFormData(url: string, data: any) {
    const requestUrl = `${this.apiBaseUrl}${url}`;
    const body = data;
    return this.http.post(requestUrl, body);
  }

  put(url: string, data: any) {
    const requestUrl = `${this.apiBaseUrl}${url}`;
    const body = JSON.stringify(data);
    return this.http.put(requestUrl, body, httpOptions);
  }

  delete(url: string) {
    const requestUrl = `${this.apiBaseUrl}${url}`;
    return this.http.delete(requestUrl, httpOptions);
  }
}
