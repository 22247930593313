import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as AuthenticationActions from './authentication.actions';
import { LoginData } from './authentication.models';
import * as fromAuthentication from './authentication.reducer';
import * as AuthenticationSelectors from './authentication.selectors';

@Injectable()
export class AuthenticationFacade {
  isAuthenticated$ = this.store.pipe(select(AuthenticationSelectors.isAuthenticated));
  authenticationState$ = this.store.pipe(select(AuthenticationSelectors.getAuthenticationState));
  constructor(private store: Store<fromAuthentication.AuthenticationPartialState>) {}

  sendAuthentication({ email, pin }: LoginData, navTargets: Record<string, string>): void {
    this.store.dispatch(
      AuthenticationActions.sendAuthentication({
        loginData: { email, pin },
        navTargets,
      })
    );
  }

  logOut() {
    this.store.dispatch(AuthenticationActions.resetAuthentication());
  }
}
