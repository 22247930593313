import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutModule } from './modules/layout/layout.module';
import { LayoutComponent } from './modules/layout/layout.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'application',
        pathMatch: 'full',
      },
      {
        path: 'application',
        loadChildren: () =>
          import('./modules/loan-application/loan-application.module').then(
            (m) => m.LoanApplicationModule
          ),
      },
      {
        path: 'verify-email',
        loadChildren: () => import('./modules/email-verification/email-verification.module').then(m => m.EmailVerificationModule)
      }
    ],
  },
  // {
  //   path: 'auth',
  //   loadChildren: () =>
  //     import('src/app/modules/authentication/authentication.module').then(
  //       (m) => m.AuthenticationModule
  //     ),
  // },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), LayoutModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
