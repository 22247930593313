<nav class="navbar shadow navbar-expand-lg navbar-light fixed-top bg-white" id="header">
  <div class="container px-1">
    <div class="navbar-translate text-xs">
      <img src="assets/imgs/logo.svg" alt="" height="50" />
      <!-- <h5 class="text-white">Money Marque</h5> -->
    </div>
    <div class="" id="">
      <ul class="ml-auto">
        <li class="nav-item"><a class="text-primary text-underline" href="https://accounts.moneymarqueng.com">My Dashboard</a></li>
      </ul>
    </div>
  </div>
</nav>
