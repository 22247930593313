import { createReducer, on } from '@ngrx/store';
import {
  sendLoanApplication,
  reset,
  sendPersonalInformation,
  sendEmploymentInformation,
  sendOffer,
  sendAccountDetails,
} from './loan-application.actions';
import { LoanApplicationResultI } from './loan-application.model';

export const LOAN_APPLICATION_FEATURE_KEY = 'loanApplication';

export interface LoanApplicationState extends LoanApplicationResultI {
  error?: null | string; // last none error (if any)
}

export interface LoanApplicationPartialState {
  readonly [LOAN_APPLICATION_FEATURE_KEY]: LoanApplicationState;
}

export const DEFAULT_LOAN_APPLICATION: LoanApplicationState = {
  loan_id: null,
  personalInformation: {},
  employmentInformation: {},
  offer: {},
  accountDetails: {},
};

export const initialState: LoanApplicationState = DEFAULT_LOAN_APPLICATION;

const cloanApplicationReducer = createReducer(
  initialState,
  on(sendLoanApplication, (state, { loanApplication }) => ({
    ...state,
    ...loanApplication,
  })),
  on(sendPersonalInformation, (state, { personalInformation }) => ({
    ...state,
    personalInformation,
  })),
  on(sendEmploymentInformation, (state, { employmentInformation }) => ({
    ...state,
    employmentInformation,
  })),
  on(sendOffer, (state, { offer }) => ({
    ...state,
    offer,
  })),
  on(sendAccountDetails, (state, { accountDetails }) => ({
    ...state,
    accountDetails,
  })),
  on(reset, (state) => ({
    ...state,
    ...initialState,
  }))
);

export function loanApplicationReducer(state, action) {
  return cloanApplicationReducer(state, action);
}
