import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'money-marque';

  constructor(private updates: SwUpdate) {
    this.updates.available.subscribe((event) => {
      // console.log('current version is', event.current);
      // console.log('available version is', event.available);
      // localStorage.setItem('version', event.current.appData['version']);
      // localStorage.setItem('versionHash', event.current.hash);
      if (confirm('An update is available for this application. Click OK to install the update')) {
        this.updateApp();
      }
    });
    this.updates.activated.subscribe((event) => {
      localStorage.setItem('activatedVersion', event.previous.appData['version']);
      localStorage.setItem('activatedVersionHash', event.previous.hash);
      // console.log('old version was', event.previous);
      // console.log('new version is', event.current);
    });
  }

  updateApp() {
    this.updates.activateUpdate().then(() => {
      window.location.reload();
    });
  }
}
