import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { AuthenticationFacade } from './modules/authentication/state/authentication.facade';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService } from './services/http/http-interceptor.service';
import { localStorageSync } from 'ngrx-store-localstorage';
import {
  reducer,
  AUTHENTICATION_FEATURE_KEY,
} from './modules/authentication/state/authentication.reducer';
import { ToastrModule } from 'ngx-toastr';
import {
  loanApplicationReducer,
  LOAN_APPLICATION_FEATURE_KEY,
} from './modules/loan-application/state/loan-application.reducer';

export function clearState(stateReducer: any) {
  return (state: any, action: any) => {
    if (action.type === '[Authentication] Reset Authentication') {
      state = undefined;
    } else if (action.type === '[Loan Application] Reset') {
      state = undefined;
    }
    return stateReducer(state, action);
  };
}

export function localStorageSyncReducer(actionReducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [AUTHENTICATION_FEATURE_KEY, LOAN_APPLICATION_FEATURE_KEY],
    rehydrate: true,
  })(actionReducer);
}

export const metaReducers: MetaReducer<any>[] = !environment.production
  ? [clearState, localStorageSyncReducer]
  : [clearState, localStorageSyncReducer];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    StoreModule.forRoot(
      { authentication: reducer, loanApplication: loanApplicationReducer },
      { metaReducers }
    ),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ToastrModule.forRoot({
      progressBar: true,
      timeOut: 6000,
      positionClass: 'toast-top-center',
      countDuplicates: true,
    }),
  ],
  providers: [
    // AuthenticationFacade,
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
