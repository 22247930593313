import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { AuthenticationFacade } from '../authentication/state/authentication.facade';
import { LayoutComponent } from './layout.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [HeaderComponent, LayoutComponent],
  imports: [CommonModule, RouterModule],
  exports: [HeaderComponent],
  providers: [AuthenticationFacade],
})
export class LayoutModule {}
